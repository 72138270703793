export class CourtConnection {
  constructor({courtName, agencyId, courtId}: { courtName: string, agencyId: string, courtId: string }){
      this.courtName = courtName;
      this.agencyId = agencyId;
      this.courtId = courtId;
      this.isSelected = false;
  }

  /** Name of the destination court */
  courtName: string;
  /** ID of the source agency */
  agencyId: string;
  /** ID of the destination court */
  courtId: string;
  /** If the court will receive warrant info */
  isSelected: boolean;
}

import { Injectable, NgZone } from '@angular/core';
import { Observable, Subscription, fromEvent, merge, switchMap, tap, timer } from 'rxjs';
import { UserService } from '../user-service/user.service';
import { DebugService } from '../debug-service/debug.service';
import { ErrorService } from '../error-service/error.service';
import { AlertController } from '@ionic/angular';
import { UtiltyService } from '../utility-service/utilty.service';

@Injectable({
  providedIn: 'root',
})
export class ActivityService {
  /** Name of the service */
  private serviceName = 'activity-service';
  /** User activity (any user activity) */
  private userActivity$: Observable<Event> | null = null;
  /** How long the user has been inactive */
  private inactivityTimer$: Observable<any> | null = null;
  /** The subscription to the timer */
  private timerSubscription: Subscription | null = null;
  /** The subscription to the countdown */
  private countdownSubscription: Subscription | null = null;
  /** The modal warning the user they will be logged out */
  private warningAlert: HTMLIonAlertElement | null = null;
  /** When the warning should occur (seconds) */
  private warningTime = 14 * 60; // 14 minutes
  /** When the user should be logged out (seconds) */
  private logoutTime = 15 * 60; // 15 minutes
  /** The last time the user was on the page */
  private lastTimeOnPage = new Date();

  constructor(
    private debugService: DebugService,
    private errorService: ErrorService,
    private ngZone: NgZone,
    private userService: UserService,
    private alertController: AlertController,
    private utilityService: UtiltyService,
  ) {
    try {
      this.userActivity$ = merge(
        fromEvent(window, 'mousemove'),
        fromEvent(window, 'scroll'),
        fromEvent(window, 'keydown'),
        fromEvent(window, 'click'),
        fromEvent(document, 'visibilitychange').pipe(
          tap(() => {
            if (!document.hidden) {
              // The current time
              const now = new Date();
              // Get the difference in seconds between now and the last time they were on the page
              const diff = this.utilityService.getSecondsBetweenDates(this.lastTimeOnPage, now);
              // If the difference is greater than the logout time, log the user out
              if(diff > this.logoutTime) {
                this.handleActivity(this.logoutTime); // Artificially log the user out
              }
            } else {
              // The user is not on the page
              this.lastTimeOnPage = new Date();
            }
          })
        )
      );
  
      // Define the inactivity timer
      this.inactivityTimer$ = this.userActivity$.pipe(
        switchMap(() => timer(0, 1000)), // Emit every second
        tap((seconds) => this.ngZone.run(() => this.handleActivity(seconds)))
      );
  
      // Subscribe to the timer
      this.userService.currentUserSubject.subscribe(user => {
        if (user.id === '') this.stopWatching();
        else {
          this.startWatching();
          // Start the timer even if the user doesn't move
          this.triggerInitialEvent();
        }
      });
    } catch (error) {
      this.errorService.logError(`${this.serviceName} - constructor`, error);
    }
  }

  /**
   * Start the timer in place of user movement
   */
  private triggerInitialEvent = () => {
    try {
      // Trigger a fake initial event to start the timer
      window.dispatchEvent(new Event('mousemove'));
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - triggerInitialEvent`, error);
    }
  }

  /**
   * Start watching user activity
   */
  public startWatching = () => {
    try {
      this.timerSubscription = this.inactivityTimer$!.subscribe();
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - startWatching`, error);
    }
  }

  /**
   * Stop watching user activity
   */
  public stopWatching = () => {
    try {
      if (this.timerSubscription) {
        this.timerSubscription.unsubscribe();
      }
      if (this.countdownSubscription) {
        this.countdownSubscription.unsubscribe();
      }
      if (this.warningAlert) {
        this.warningAlert.dismiss();
      }
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - stopWatching`, error);
    }
  }

  /**
   * Handle the user activity
   * @param seconds Number of seconds waiting
   */
  private handleActivity = async (seconds: number) => {
    try {
      if (seconds === this.warningTime) {
        this.showWarningAlert(this.logoutTime - this.warningTime);
      }
      // Action to be performed after the inactivity window
      if(seconds === this.logoutTime) {
        if (this.warningAlert) {
          this.warningAlert.dismiss();
        }
        const explanationAlert = await this.alertController.create({
          header: 'Automatic Logout',
          message: `You have been logged out due to being inactive for ${this.logoutTime / 60} minutes.`,
          buttons:[{
            text: 'OK',
            role: 'confirm',
          }]
        });
        // Explain to the user why they were logged out
        explanationAlert.present();
        // Log the user out
        this.userService.signOut();
      }
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - handleActivity`, error);
    }
  }

  private showWarningAlert = async (countdown: number) => {
    try {
      this.warningAlert = await this.alertController.create({
        header: 'Inactivity Warning',
        subHeader: `You have been inactive for ${this.warningTime/60} minutes`,
        message: `You will be logged out in ${countdown} seconds if no activity is detected.`
      });
  
      this.warningAlert.present();
  
      this.countdownSubscription = timer(0, 1000).subscribe(seconds => {
        const remainingTime = countdown - seconds;
        // Automatically close the warning once the user has been logged out
        if (remainingTime <= 0) {
          this.warningAlert!.dismiss();
          this.countdownSubscription!.unsubscribe();
        }
      });
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - showWarningAlert`, error);
    }
  }
}

import { Injectable } from '@angular/core';
import { DebugService } from '../debug-service/debug.service';
import { ErrorService } from '../error-service/error.service';
import { downloadData, getUrl, remove, uploadData } from 'aws-amplify/storage';

@Injectable({
  providedIn: 'root'
})
export class S3Service {
  /** Service name */
  private serviceName = 's3-service';

  constructor(
    private debugService: DebugService,
    private errorService: ErrorService
  ) { }

  //----- Files -----//
  /**
   * Save file to S3
   * @param accessLevel Access level of the file being created
   * @param path Path in storage for the file
   * @param file File to be saved
   */
  public handleSaveFile = async (accessLevel: 'public' | 'protected'| 'private', path: string, file: File) => {
    try {
      let uploadResult;
      if(accessLevel === 'private') {
        uploadResult = await uploadData({
          path: ({identityId}) => `${accessLevel}/${identityId}/${path}`,
          data: file
        }).result;
      } else if(accessLevel === 'public') {
        uploadResult = await uploadData({
          path: ({identityId}) => `${accessLevel}/${path}`,
          data: file
        }).result;
      }
      this.debugService.logData(`${this.serviceName} - handleSaveFile - upload result:`, uploadResult);
      return uploadResult;
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - handleSaveFile`, error);
    }
  }

  /**
   * Get url for s3 file
   * @param accessLevel Access level of the file being accessed
   * @param path Path in storage for the file
   */
  public handleGetUrl = async (accessLevel: 'public' | 'protected'| 'private', path: string) => {
    try {
      let getUrlResult;
      if(accessLevel === 'private') {
        getUrlResult = await getUrl({
          path: ({identityId}) => `${accessLevel}/${identityId}/${path}`,
          options: {
            validateObjectExistence: true, // Check if object exists before creating a URL
            expiresIn: 60, // Expires in 60 seconds
          }
        });
      } else if(accessLevel === 'public') {
        getUrlResult = await getUrl({
          path: ({identityId}) => `${accessLevel}/${path}`,
          options: {
            validateObjectExistence: true, // Check if object exists before creating a URL
            expiresIn: 60, // Expires in 60 seconds
          }
        });
      }
      this.debugService.logData(`${this.serviceName}- handleGetUrl - getUrlResult`, getUrlResult);
      return getUrlResult;
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - handleGetUrl`, error);
    }
  }

  /**
   * Get file from s3
   * @param accessLevel Access level of the file being accessed
   * @param path Path in storage for the file
   */
  public handleGetFile = async (accessLevel: 'public' | 'protected'| 'private', path: string) => {
    try {
      let downloadResult;
      if(accessLevel === 'private') {
        downloadResult = await downloadData({
          path: ({identityId}) => `${accessLevel}/${identityId}/${path}`,
          options: {
            onProgress: progress => {
              this.debugService.logData(`${this.serviceName} - handleGetFile`, `Download progress: ${(progress.transferredBytes/progress.totalBytes!) * 100}%`);
            }
          }
        }).result;
      } else if(accessLevel === 'public') {
        downloadResult = await downloadData({
          path: ({identityId}) => `${accessLevel}/${path}`,
          options: {
            onProgress: progress => {
              this.debugService.logData(`${this.serviceName} - handleGetFile`, `Download progress: ${(progress.transferredBytes/progress.totalBytes!) * 100}%`);
            }
          }
        }).result;
      }
      return downloadResult;
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - handleGetFile`, error);
    }
  }

  /**
   * Remove file from s3
   * @param accessLevel Access level of the file being accessed
   * @param path Path in storage for the file
   */
  public handleRemoveFile = async (accessLevel: 'public' | 'protected'| 'private', path: string) => {
    try {
      if(accessLevel === 'private') {
        await remove({
          path: ({identityId}) => `${accessLevel}/${identityId}/${path}`,
        });
      } else if(accessLevel === 'public') {
        await remove({
          path: ({identityId}) => `${accessLevel}/${path}`,
        });
      }
    } catch (error) {
      throw this.errorService.passError(`${this.errorService} - handleRemoveFile`, error);
    }
  }
}

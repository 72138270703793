import { Injectable } from '@angular/core';
import { ErrorService } from '../error-service/error.service';
import { AlertController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class CenturionService {
  /** Name of the service */
  private serviceName = 'centurion-service';

  constructor(
    private errorService: ErrorService,
    private alertController: AlertController
  ) { }

  /**
   * Get the url of the current user agreement
   * @returns User agreement url
   */
  public getUserAgreementUrl = () => {
    try {
      return './assets/userAgreement/07.02.22.pdf';
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - getUserAgreementUrl`, error);
    }
  }

  /**
   * Get the string representing the current user agreement version
   * @returns User agreement version number
   */
  public getUserAgreementLatestVersion = () => {
    try {
      return '07.02.22';
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - getUserAgreementLatestVersion`, error);
    }
  }
}

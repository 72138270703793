import { Injectable } from '@angular/core';
import { UserService } from '../user-service/user.service';
import { DebugService } from '../debug-service/debug.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {
  /** Name of service */
  private serviceName = 'permission-service';

  constructor(
    private userService: UserService,
    private debugService: DebugService
  ) { }

  /** Can activate function used to allow navigation if a user is signed in */
  public canActivate = async () => {
    if(await this.userService.verifyToken()) {
      this.debugService.logData(`${this.serviceName} - canActivate:`, 'entry permitted');
      return true;
    }
    else {
      this.debugService.logData(`${this.serviceName} - canActivate:`, 'entry NOT permitted');
      return false;
    }
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Analytic } from 'src/app/classes/analytics/analytic/analytic';
import { DebugService } from '../debug-service/debug.service';
import { Router } from '@angular/router';
import { UtiltyService } from '../utility-service/utilty.service';
import { Warrant } from 'src/app/classes/warrants/warrant/warrant';
import { ChangelogItem } from 'src/app/classes/analytics/changelog-item/changelog-item';
import { User } from 'src/app/classes/users/user/user';
import { DynamoService } from '../dynamo-service/dynamo.service';
import { ErrorService } from '../error-service/error.service';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  /** Name of the service */
  private serviceName = 'analytics-service';
  /** Blank analytics object */
  private blankAnalytic: Analytic = {};
  /** Blank changelog item */
  private blankChangelog: ChangelogItem = {};
  /** New changelog item */
  private newChangelogItem: ChangelogItem = {};

  /** The current analytic */
  public currentAnalytic = new BehaviorSubject<Analytic>(this.blankAnalytic);

  constructor(
    private debugService: DebugService,
    private errorService: ErrorService,
    private utilityService: UtiltyService,
    private dynamoService: DynamoService,
    private router: Router,
  ) {
    this.currentAnalytic.subscribe((analytic) => {
      this.debugService.logData(`${this.serviceName} - currentAnalyticSubject - New Value:`, analytic);
    });
  }

  //* ----- DYNAMO
  /**
   * Create analytic in the dynamo database
   * @param warrant The warrant being added to the dynamo database
   * @returns RestApi post response
   */
  public createAnalytic = async (analytic: Analytic) => {
    try {
      return await this.dynamoService.handleCreateAnalytic(analytic);
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - createAnalytic`, error);
    }
  }

  /**
   * Get Analytic in the dynamo database
   * @param analyticId The analytic id of the analytic being pulled from the database (same as warrant id)
   * @returns Analytic object
   */
  public getAnalytic = async (analyticId: string) => {
    try {
      const analyticApiResponse = await this.dynamoService.handleGetAnalytic(analyticId);
      this.debugService.logData(`${this.serviceName} - getAnalytic - analyticApiResponse`, analyticApiResponse);
      if(analyticApiResponse.statusCode === 200) {
        const dynamoAnalytic = await analyticApiResponse.body.json() as Analytic;
        this.debugService.logData(`${this.serviceName} - getAnalytic - analytic body json:`, dynamoAnalytic);
        return dynamoAnalytic;
      } else {
        throw new Error('Invalid get analytic response');
      }
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - getAnalytic`, error);
    }
  }

  /**
   * Update Analytic in the dynamo database
   * @param updatedAnalytic The analytic being updated in the dynamo database
   * @returns RestApi put resonse
   */
  public updateAnalytic = async (updatedAnalytic: Analytic) => {
    try {
      return await this.dynamoService.handleUpdateAnalytic(updatedAnalytic);
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - updateAnalytic`, error);
    }
  }

  /**
   * Delete analytic in the dynamo database
   * @param warrantId The id of the analytic to be deleted from dynamo (same as warrant id)
   * @returns RestApi delete response
   */
  public deleteAnalytic = async (analyticId: string) => {
    try {
      const response = await this.dynamoService.handleDeleteAnalytic(analyticId);
      return response;
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - deleteAnalytic`, error);
    }
  }

  //* ----- LOCAL
  /**
   * Resets the current analytic
   */
  public resetCurrentAnalytic = () => {
    this.currentAnalytic.next(this.blankAnalytic);
  }

  /**
   * Sets the current analytic
   * @param analytic Analytic to become the current warrant
   */
  public setCurrentAnalytic = (analytic: Analytic) => {
    this.currentAnalytic.next(analytic);
  }

  /**
   * Returns the current analytic
   * @returns The current analytic
   */
  public getCurrentAnalytic = () : Analytic => {
    return this.currentAnalytic.getValue();
  }

  //* ----- HELPER METHODS
  /**
   * Start a new anylytic
   * @param currentWarrant The corresponding warrant
   * @param isReplicated If the warrant is replicated
   * @param isLegacy If the warrant is a legacy warrant
   */
  public startAnalytic = (currentWarrant: Warrant, currentUser: User, isReplicated = false, isLegacy = false) => {
    try {
      this.setCurrentAnalytic({
        agencyName: currentWarrant.agencyName,
        changelog: [],
        creatorBadgeNumber: currentUser.badge,
        creatorFirstName: currentUser.firstName,
        creatorId: currentUser.id,
        creatorLastName: currentUser.lastName,
        creatorTitle: currentUser.title,
        id: currentWarrant.id,
        isLegacy: isLegacy,
        isReplicated: isReplicated,
        status: currentWarrant.status,
        tsCreated: currentWarrant.dateSetup,
        type: currentWarrant.type,
      });
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - endChangelogCapture`, error);
    }
  }

  /**
   * Start capturing a changelog item
   * @param componentName Name of the compenent the action is taking place
   * @param User The user making the changes 
   * @param action The action being taken by the user
   */
  public startChangelogCapture = ( componentName: string, { title, firstName, lastName, badge, id }: User ) => {
    try {
      this.newChangelogItem = { ...this.blankChangelog };
      this.newChangelogItem = {
        page: componentName,
        userTitle: title,
        userFirstName: firstName,
        userLastName: lastName,
        userBadgeNumber: badge,
        userId: id,
        startTime: new Date().toISOString(),
      }
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - startChangelogCapture`, error);
    }
  }

  /**
   * Finish capturing the changelog item
   * @param action What was done in this time captured by the changelog
   * @param currentWarrant The current warrant
   */
  public endChangelogCapture = (action: 'edit' | 'create' | 'delete', currentWarrant: Warrant) => {
    try {
      this.newChangelogItem.action = action;
      this.newChangelogItem.endTime = new Date().toISOString();
      const currentAnalytic = this.getCurrentAnalytic();
      // Copy the new item (since the reference will be rewritten)
      const changelogItemToAdd = this.utilityService.primativeCopy(this.newChangelogItem) as ChangelogItem;
      const oldChangeLog = currentAnalytic.changelog;
      let changelogToAdd: ChangelogItem[] = [];
      if(oldChangeLog) changelogToAdd = oldChangeLog;
      changelogToAdd.push(changelogItemToAdd);
      // Get any new data from the changelog addition
      const newTimesAndCounts = this.addChanglogTimeAndCountToAnalytic(this.newChangelogItem.page!, currentWarrant);
      // Update Local Data Store
      this.setCurrentAnalytic({
        ...currentAnalytic,
        ...newTimesAndCounts,
        changelog: changelogToAdd
      });
      // Update Dynamo
      this.updateAnalytic({
        id: currentAnalytic.id,
        ...newTimesAndCounts,
        changelog: changelogToAdd
      });
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - endChangelogCapture`, error);
    }
  }

  private addChanglogTimeAndCountToAnalytic = (page: string, currentWarrant: Warrant) => {
    try {
      this.debugService.logData(`${this.serviceName} - addChanglogTimeAndCountToAnalytic - currentWarrant`, currentWarrant);
      const currentAnalytic = this.getCurrentAnalytic();
      const newTimesAndCounts: Analytic = {};
      // Get time difference between two ISO time strings
      const timeDiff = this.utilityService.getSecondsBetweenISOStrings(this.newChangelogItem.startTime!, this.newChangelogItem.endTime!);
      switch(page) {
        // Report Number Page
        case 'report-number-page':
          if(currentAnalytic.setupPageTime) {
            newTimesAndCounts.setupPageTime = currentAnalytic.setupPageTime + timeDiff;
          } else {
            newTimesAndCounts.setupPageTime = timeDiff;
            switch(currentWarrant.type) {
              case 'warrant':
                this.router.navigate(['/offenses'], { replaceUrl: true });
                break;
              case 'order':
                this.router.navigate(['/offenses'], { replaceUrl: true });
                break;
              case 'gps':
                this.router.navigate(['/offenses'], { replaceUrl: true });
                break;
              case 'specialized':
                this.router.navigate(['/offenses'], { replaceUrl: true });
                break;
              default:
                break;
            }
          }
          // newTimesAndCounts.setupPageTime = currentAnalytic.setupPageTime ? currentAnalytic.setupPageTime + timeDiff : timeDiff;
          break;
        // Offenses Page
        case 'offenses-page':
          if(currentAnalytic.offensesTime) {
            newTimesAndCounts.offensesTime = currentAnalytic.offensesTime + timeDiff;
          } else {
            newTimesAndCounts.offensesTime = timeDiff;
            // switch(currentWarrant.type) {
            //   case 'warrant':
            //     this.router.navigate(['/searches'], { replaceUrl: true });
            //     break;
            //   case 'order':
            //     this.router.navigate(['/searches'], { replaceUrl: true });
            //     break;
            //   case 'gps':
            //     this.router.navigate(['/searches'], { replaceUrl: true });
            //     break;
            //   case 'specialized':
            //     this.router.navigate(['/company-info'], { replaceUrl: true });
            //     break;
            //   default:
            //     break;
            // }
          }
          // newTimesAndCounts.offensesTime = currentAnalytic.offensesTime ? currentAnalytic.offensesTime + timeDiff : timeDiff;
          newTimesAndCounts.offensesCount = currentWarrant.offenses?.length ?? 0;
          break;
        // Searches Page
        case 'searches-page':
          if(currentAnalytic.searchesTime) {
            newTimesAndCounts.searchesTime = currentAnalytic.searchesTime + timeDiff;
          } else {
            newTimesAndCounts.searchesTime = timeDiff;
            // switch(currentWarrant.type) {
            //   case 'warrant':
            //     this.router.navigate(['/grounds'], { replaceUrl: true });
            //     break;
            //   case 'order':
            //     this.router.navigate(['/evidences'], { replaceUrl: true });
            //     break;
            //   case 'gps':
            //     this.router.navigate(['/grounds'], { replaceUrl: true });
            //     break;
            //   default:
            //     break;
            // }
          }
          newTimesAndCounts.searchesCount = currentWarrant.searches?.length ?? 0;
          break;
        // Grounds Page
        case 'warrant-grounds-component':
          if(currentAnalytic.groundsPageTime) {
            newTimesAndCounts.groundsPageTime = currentAnalytic.groundsPageTime + timeDiff;
          } else {
            newTimesAndCounts.groundsPageTime = timeDiff;
            switch(currentWarrant.type) {
              case 'warrant':
                this.router.navigate(['/evidences'], { replaceUrl: true });
                break;
              default:
                break;
            }
          }
          // newTimesAndCounts.groundsPageTime = currentAnalytic.groundsPageTime ? currentAnalytic.groundsPageTime + timeDiff : timeDiff;
          break;
        case 'specialized-grounds-component':
          if (currentAnalytic.groundsPageTime) {
            newTimesAndCounts.groundsPageTime = currentAnalytic.groundsPageTime + timeDiff;
          } else {
            newTimesAndCounts.groundsPageTime = timeDiff;
            switch(currentWarrant.type) {
              case 'specialized':
                this.router.navigate(['/evidences'], { replaceUrl: true });
                break;
              default:
                break;
            }
          }
          // newTimesAndCounts.groundsPageTime = currentAnalytic.groundsPageTime ? currentAnalytic.groundsPageTime + timeDiff : timeDiff;
          break;
        case 'gps-grounds-component':
          if(currentAnalytic.groundsPageTime) {
            newTimesAndCounts.groundsPageTime = currentAnalytic.groundsPageTime + timeDiff;
          } else {
            newTimesAndCounts.groundsPageTime = timeDiff;
            switch(currentWarrant.type) {
              case 'gps':
                this.router.navigate(['/narrative'], { replaceUrl: true });
                break;
              default:
                break;
            }
          }
          // newTimesAndCounts.groundsPageTime = currentAnalytic.groundsPageTime ? currentAnalytic.groundsPageTime + timeDiff : timeDiff;
          break;
        // Evidence Page
        case 'warrant-evidences-component':
          if(currentAnalytic.evidencesTime) {
            newTimesAndCounts.evidencesTime = currentAnalytic.evidencesTime + timeDiff;
          } else {
            newTimesAndCounts.evidencesTime = timeDiff;
            // switch(currentWarrant.type) {
            //   case 'warrant':
            //     this.router.navigate(['/narrative'], { replaceUrl: true });
            //     break;
            //   default:
            //     break;
            // }
          }
          // newTimesAndCounts.evidencesTime = currentAnalytic.evidencesTime ? currentAnalytic.evidencesTime + timeDiff : timeDiff;
          newTimesAndCounts.evidencesCount = currentWarrant.evidences?.length ?? 0;
          break;
        case 'court-evidences-component':
          if(currentAnalytic.evidencesTime) {
            newTimesAndCounts.evidencesTime = currentAnalytic.evidencesTime + timeDiff;
          } else {
            newTimesAndCounts.evidencesTime = timeDiff;
            switch(currentWarrant.type) {
              case 'order':
                this.router.navigate(['/narrative'], { replaceUrl: true });
                break;
              default:
                break;
            }
          }
          // newTimesAndCounts.evidencesTime = currentAnalytic.evidencesTime ? currentAnalytic.evidencesTime + timeDiff : timeDiff;
          newTimesAndCounts.evidencesCount = [
            currentWarrant.isPhoto,
            currentWarrant.isPrints,
            currentWarrant.isSaliva,
            currentWarrant.isBlood,
            currentWarrant.isUrine,
            currentWarrant.isHair,
            currentWarrant.isHandwriting,
            currentWarrant.isMeasurements,
            currentWarrant.isVoice,
            currentWarrant.isFootprints
          ].filter(Boolean).length;
          break;
        // Narrative Page
        case 'narrative-page':
          if(currentAnalytic.narrativePageTime) {
            newTimesAndCounts.narrativePageTime = currentAnalytic.narrativePageTime + timeDiff;
          } else {
            newTimesAndCounts.narrativePageTime = timeDiff;
            switch(currentWarrant.type) {
              case 'warrant':
                this.router.navigate(['/service-times'], { replaceUrl: true });
                break;
              case 'order':
                this.router.navigate(['/endorsement'], { replaceUrl: true });
                break;
              case 'gps':
                this.router.navigate(['/optional-items'], { replaceUrl: true });
                break;
              case 'specialized':
                this.router.navigate(['/service-times'], { replaceUrl: true });
                break;
              default:
                break;
            }
          }
          // newTimesAndCounts.narrativePageTime = currentAnalytic.narrativePageTime ? currentAnalytic.narrativePageTime + timeDiff : timeDiff;
          break;
        // Service Time Page
        case 'service-time-page':
          if(currentAnalytic.servicePageTime) {
            newTimesAndCounts.servicePageTime = currentAnalytic.servicePageTime + timeDiff;
          } else {
            newTimesAndCounts.servicePageTime = timeDiff;
            switch(currentWarrant.type) {
              case 'warrant':
                this.router.navigate(['/optional-items'], { replaceUrl: true });
                break;
              case 'specialized':
                this.router.navigate(['/optional-items'], { replaceUrl: true });
                break;
              default:
                break;
            }
          }
          // newTimesAndCounts.servicePageTime = currentAnalytic.servicePageTime ? currentAnalytic.servicePageTime + timeDiff : timeDiff;
          break;
        // Optional Items Page
        case 'optional-items-page':
          if (currentAnalytic.optionalPageTime) {
            newTimesAndCounts.optionalPageTime = currentAnalytic.optionalPageTime + timeDiff;
          } else {
            newTimesAndCounts.optionalPageTime = timeDiff;
            switch(currentWarrant.type) {
              case 'warrant':
                this.router.navigate(['/endorsement'], { replaceUrl: true });
                break;
              case 'gps':
                this.router.navigate(['/endorsement'], { replaceUrl: true });
                break;
              case 'specialized':
                this.router.navigate(['/special-orders'], { replaceUrl: true });
                break;
              default:
                break;
            }
          }
          // newTimesAndCounts.optionalPageTime = currentAnalytic.optionalPageTime ? currentAnalytic.optionalPageTime + timeDiff : timeDiff;
          newTimesAndCounts.optionsCount = [
            currentWarrant.isExpedited,
            currentWarrant.isNoKnock,
            currentWarrant.experience,
            currentWarrant.informant,
            currentWarrant.isDelayed,
            currentWarrant.isSealed,
            currentWarrant.isCellPhoneDownloadDelay,
            currentWarrant.isNonDisclosure
          ].filter(Boolean).length;
          break;
        // Endorsement Page
        case 'endorsement-page':
          if(currentAnalytic.approvePageTime) {
            newTimesAndCounts.approvePageTime = currentAnalytic.approvePageTime + timeDiff;
          } else {
            newTimesAndCounts.approvePageTime = timeDiff;
            switch(currentWarrant.type) {
              case 'warrant':
                this.router.navigate(['/preview'], { replaceUrl: true });
                break;
              case 'order':
                this.router.navigate(['/preview'], { replaceUrl: true });
                break;
              case 'gps':
                this.router.navigate(['/preview'], { replaceUrl: true });
                break;
              case 'specialized':
                this.router.navigate(['/preview'], { replaceUrl: true });
                break;
              default:
                break;
            }
          }

          // newTimesAndCounts.submitPageTime = currentAnalytic.submitPageTime ? currentAnalytic.submitPageTime + timeDiff : timeDiff;
          newTimesAndCounts.warrantCreateTime = 
            (currentAnalytic.accountsTime ?? 0) +
            (currentAnalytic.approvePageTime ?? 0) +
            (currentAnalytic.companyPageTime ?? 0) +
            (currentAnalytic.courtPageTime ?? 0) +
            (currentAnalytic.evidencesTime ?? 0) +
            (currentAnalytic.groundsPageTime ?? 0) +
            (currentAnalytic.narrativePageTime ?? 0) +
            (currentAnalytic.offensesTime ?? 0) +
            (currentAnalytic.optionalPageTime ?? 0) +
            (currentAnalytic.searchesTime ?? 0) +
            (currentAnalytic.servicePageTime ?? 0) +
            (currentAnalytic.setupPageTime ?? 0) +
            (currentAnalytic.specialOrdersPageTime ?? 0) +
            (currentAnalytic.submitPageTime ?? 0);
          break;
        // Company Info Page
        case 'company-info-page':
          if(currentAnalytic.companyPageTime) {
            newTimesAndCounts.companyPageTime = currentAnalytic.companyPageTime + timeDiff;
          } else {
            newTimesAndCounts.companyPageTime = timeDiff;
            switch(currentWarrant.type) {
              case 'specialized':
                this.router.navigate(['/accounts'], { replaceUrl: true });
                break;
              default:
                break;
            }
          }
          // newTimesAndCounts.companyPageTime = currentAnalytic.companyPageTime ? currentAnalytic.companyPageTime + timeDiff : timeDiff;
          break;
        // Accounts Page
        case 'accounts-page':
          if(currentAnalytic.accountsTime) {
            newTimesAndCounts.accountsTime = currentAnalytic.accountsTime + timeDiff;
          } else {
            newTimesAndCounts.accountsTime = timeDiff;
            // switch(currentWarrant.type) {
            //   case 'specialized':
            //     this.router.navigate(['/grounds'], { replaceUrl: true });
            //     break;
            //   default:
            //     break;
            // }
          }
          // newTimesAndCounts.accountsTime = currentAnalytic.accountsTime ? currentAnalytic.accountsTime + timeDiff : timeDiff;
          newTimesAndCounts.accountsCount = currentWarrant.accounts?.length ?? 0;
          break;
        // Special orders page
        case 'special-orders-page':
          if(currentAnalytic.specialOrdersPageTime) {
            newTimesAndCounts.specialOrdersPageTime = currentAnalytic.specialOrdersPageTime + timeDiff;
          } else {
            newTimesAndCounts.specialOrdersPageTime = timeDiff;
            switch(currentWarrant.type) {
              case 'specialized':
                this.router.navigate(['/endorsement'], { replaceUrl: true });
                break;
              default:
                break;
            }
          }
          // newTimesAndCounts.specialOrdersPageTime = currentAnalytic.specialOrdersPageTime ? currentAnalytic.specialOrdersPageTime + timeDiff : timeDiff;
          newTimesAndCounts.specialOrdersCount = [
            currentWarrant.specialOrderIsAdverseAction,
            currentWarrant.specialOrderIsAssociatedNumbersOrder,
            currentWarrant.specialOrderIsAuthenticityRecord,
            currentWarrant.specialOrderIsCAPenalCode,
            currentWarrant.specialOrderIsCellSiteSimulator,
            currentWarrant.specialOrderIsFundSiezureOrder,
            currentWarrant.specialOrderIsOngoingLocationData,
            currentWarrant.specialOrderIsPhonePenRegisterTrace,
            currentWarrant.specialOrderIsPing,
            currentWarrant.specialOrderIsServiceTransferOrder,
            currentWarrant.specialOrderIsVehiclePingOrder,
            currentWarrant.specialOrderisCarrierKeyAndCellSiteList
          ].filter(Boolean).length;
          break;
        default:
          throw new Error('Invalid page' + page);
      }

      return newTimesAndCounts;
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - addChanglogTimeToAnalytic`, error);
    }
  }
}

import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { DebugService } from '../debug-service/debug.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {
  /** Service name */
  private serviceName = 'error-service';

  constructor(
    private toastController: ToastController,
    private debugService: DebugService,
  ) { }

  /**
   * Present a toast communicating that an error occured
   * @param message Message stated by the toast
   */
  public showErrorToast = async (message: string) => {
    const errorToast = this.toastController.create({
      message: message,
      duration: 2000,
      color: 'danger',
      position: 'top'
    });
    (await errorToast).present();
  }

  /**
   * Log error
   * Will only log if the environment is not production
   * @param processor The serive/page/component processing the code
   * @param err The error that occured
   */
  public logError = (processor: string, err: unknown) => {
    try {
      if(!environment.production) {
        const error = this.ensureError(err);
        const newError = new Error(`${processor}\n` + error.message);
        console.error(newError.message);
      }
    } catch (error) {
      this.logError(`${this.serviceName} - logError`, error);
    }
  }

  /**
   * Ensure that an error is an error object
   * @param value Possible error object
   * @returns An error object
   */
  public ensureError = (value: unknown): Error => {
    try {
      if (value instanceof Error) return value
  
      let stringified = '[Unable to stringify the thrown value]'
      try {
        stringified = JSON.stringify(value)
      } catch {}
    
      const error = new Error(`This value was thrown as is, not through an Error: ${stringified}`)
      return error
    } catch (error) {
      throw this.passError(`${this.serviceName} - ensureError`, error);
    }
  }

  /**
   * Pass error allowing the chaining of errors
   * @param processor The serive/page/component processing the code
   * @param err The error that occured
   */
  public passError = (processor: string, err: unknown): Error => {
    try {
      const error = this.ensureError(err);
      const newError = new Error(`${processor}\n` + error.message);
      return newError;
    } catch (error) {
      throw this.passError(`${this.serviceName} - passError`, error);
    }
  }
}

import { Injectable } from '@angular/core';
import { get, post, put, del } from 'aws-amplify/api';
import { DebugService } from '../debug-service/debug.service';
import { ErrorService } from '../error-service/error.service';
import { User } from 'src/app/classes/users/user/user';
import { Warrant } from 'src/app/classes/warrants/warrant/warrant';
import { Analytic } from 'src/app/classes/analytics/analytic/analytic';
import { ServiceArea } from 'src/app/classes/warrants/returns/service-area/service-area';
import { Evidence } from 'src/app/classes/warrants/evidence/evidence';
import { Search } from 'src/app/classes/warrants/search/search';
import { Offense } from 'src/app/classes/warrants/offense/offense';

@Injectable({
  providedIn: 'root'
})
export class DynamoService {
  /** Service name */
  private serviceName = 'dynamo-service';

  constructor(
    private debugService: DebugService,
    private errorService: ErrorService,
  ) { }

  //----- User -----//
  /**
   * Create user in the user dynamo table
   * @param newUser New user to be created
   * @returns RestApi post response
   */
  public handleCreateUser = async (newUser: User) => {
    try {
      if(!newUser.id) throw new Error('User is missing id!');
      const restOperation = post({
        apiName: 'cwApi',
        path: '/user/',
        options: {
          body: newUser as FormData
        }
      });

      return await restOperation.response;
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - handleCreateUser`, error);
    }
  }

  /**
   * Get the data from the user dynamo table
   * @param subId subId of the auth user
   * @returns RestApi get response
   */
  public handleGetUser = async (subId: string) => {
    try {
      const restOperation = get({
        apiName: 'cwApi',
        path: `/user/${subId}`
      });

      return await restOperation.response;
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - handleGetUser`, error);
    }
  }

  /**
   * Update user in the user dynamo table
   * @param updatedUser User to be updated
   * @returns RestApi put response
   */
  public handleUpdateUser = async (updatedUser: User) => {
    try {
      if(!updatedUser.id) throw new Error('User is missing id!');
      const restOperation = put({
        apiName: 'cwApi',
        path: `/user/${updatedUser.id}`,
        options: {
          body: updatedUser as FormData
        }
      });

      return await restOperation.response;
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - handleUpdateUser`, error);
    }
  }

  //----- Agency -----//
  /**
   * Get the agency in dynamo
   * @returns The agency
   */
  public handleGetAgency = async () => {
    try {
      const restOperation = get({
        apiName: 'cwApi',
        path: '/agency'
      });

      return await restOperation.response;
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - handleGetAgency`, error);
    }
  }

  //----- Warrants -----//
  /**
   * Create warrant in the warrant dynamo table
   * @param newWarrant New Warrant to be created
   * @returns RestApi post response
   */
  public handleCreateWarrant = async (newWarrant: Warrant) => {
    try {
      if(!newWarrant.id) throw new Error('Warrant is missing id!');
      const restResponse = post({
        apiName: 'cwApi',
        path: '/warrant/',
        options: {
          body: newWarrant as FormData
        }
      });

      return await restResponse.response;
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - handleCreateWarrant`, error);
    }
  }

  /**
   * Get the data from the warrant dynamo table
   * @param warrantId id of the warrant
   * @returns RestApi get response
   */
  public handleGetWarrant = async (warrantId: string) => {
    try {
      const restOperation = get({
        apiName: 'cwApi',
        path: `/warrant/${warrantId}`
      });
      
      return await restOperation.response;
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - handleGetWarrant`, error);
    }
  }

  
  /**
   * Update warrant in the warrant dynamo table
   * @param updatedUser warrant to be updated
   * @returns RestApi put response
   */
  public handleUpdateWarrant = async (updatedWarrant: Warrant) => {
    try {
      if(!updatedWarrant.id) throw new Error('Warrant is missing id!');
      const restOperation = put({
        apiName: 'cwApi',
        path: `/warrant/${updatedWarrant.id}`,
        options: {
          body: updatedWarrant as FormData
        }
      });

      return await restOperation.response;
    } catch (error) {
      throw this.errorService.passError(`${this.errorService} - handleUpdateWarrant`, error);
    }
  }

  /**
   * delete warrant in the warrant dynamo table
   * @param warrantId id of the warrant to be delete
   * @returns RestApi delete response
   */
  public handleDeleteWarrant = async (warrantId: string) => {
    try {
      const restOperation = del({
        apiName: 'cwApi',
        path: `/warrant/${warrantId}`
      });

      return await restOperation.response;
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - handleDeleteWarrant`, error);
    }
  }

  /**
   * Load all the warrants in dynamo
   * @returns RestApi get response
   */
  public handleLoadAllWarrants = async () => {
    try {
      const restOperation = get({
        apiName: 'cwApi',
        path: '/warrant/all'
      });

      return await restOperation.response;
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - handleLoadAllWarrants`, error);
    }
  }

  /**
   * Load all the warrants in dynamo created by a specific user
   * @param userId Id of the user
   * @returns RestApi get response
   */
  public handleLoadAllWarrantsByCreator = async (userId: string) => {
    try {
      const restOperation = get({
        apiName: 'cwApi',
        path: `/warrant/creator/${userId}`
      });

      return await restOperation.response;
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - handleLoadAllWarrantByCreator`, error);
    }
  }

  //----- Offenses -----//
  /**
   * Get offenses by warrant id
   * @param warrantId id of the warrant
   * @returns restApi get response
   */
  public handleGetOffensesByWarrant = async (warrantId: string) => {
    try {
      const restOperation = get({
        apiName: 'cwApi',
        path: `/offense/warrant/${warrantId}`
      });

      return await restOperation.response;
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - handleGetOffensesByWarrant`, error);
    }
  }

  /**
   * Update offense in the offense dynamo table
   * @param updatedOffense Offense to be updated
   * @returns RestApi put response
   */
  public handleUpdateOffense = async (updatedOffense: Offense) => {
    try {
      if(!updatedOffense.id) throw new Error('Offense is missing id!');
      const restOperation = put({
        apiName: 'cwApi',
        path: `/offense/${updatedOffense.id}`,
        options: {
          body: updatedOffense as FormData
        }
      });

      return await restOperation.response;
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - handleUpdateOffense`, error);
    }
  }

  /**
   * Delete offense from the offense dynamo table
   * @param offenseId id of the offense to be deleted
   * @returns RestApi delete response
   */
  public handleDeleteOffense = async (offenseId: string) => {
    try {
      const restOperation = del({
        apiName: 'cwApi',
        path: `/offense/${offenseId}`
      });

      return await restOperation.response;
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - handleDeleteOffense`, error);
    }
  }

  //----- Searches -----//
  /**
   * Get searches by warrant id
   * @param warrantId id of the warrant
   * @returns restApi get response
   */
  public handleGetSearchesByWarrant = async (warrantId: string) => {
    try {
      const restOperation = get({
        apiName: 'cwApi',
        path: `/searchitem/warrant/${warrantId}`
      });

      return await restOperation.response
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - handleGetSearchesByWarrant`, error);
    }
  }

  /**
   * Update search in the search dynamo table
   * @param updatedSearch Search to be updated
   * @returns RestApi put response
   */
  public handleUpdateSearch = async (updatedSearch: Search) => {
    try {
      if(!updatedSearch.id) throw new Error('Search is missing id!');
      const restOperation = put({
        apiName: 'cwApi',
        path: `/searchitem/${updatedSearch.id}`,
        options: {
          body: updatedSearch as FormData
        }
      });

      return await restOperation.response;
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - handleUpdateSearch`, error);
    }
  }

  /**
   * Delete search from the search dynamo table
   * @param searchId id of the search to be deleted
   * @returns RestApi delete response
   */
  public handleDeleteSearch = async (searchId: string) => {
    try {
      const restOperation = del({
        apiName: 'cwApi',
        path: `/searchitem/${searchId}`
      });
      
      return await restOperation.response;
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - handleDeleteSearch`, error);
    }
  }

  //----- Evidences -----//
  /**
   * Get evidences by warrant id
   * @param warrantId id of the warrant
   * @returns restApi get response
   */
  public handleGetEvidencesByWarrant = async (warrantId: string) => {
    try {
      const restOperation = get({
        apiName: 'cwApi',
        path: `/evidence/warrant/${warrantId}`
      });

      return await restOperation.response;
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - handleGetEvidencesByWarrant`, error);
    }
  }

  /**
   * Update evidence in the evidence dynamo table
   * @param updatedEvidence Evidence to be updated
   * @returns RestApi put response
   */
  public handleUpdateEvidence = async (updatedEvidence: Evidence) => {
    try {
      if(!updatedEvidence.id) throw new Error('Evidence is missing id!');
      const restOperation = put({
        apiName: 'cwApi',
        path: `/evidence/${updatedEvidence.id}`,
        options: {
          body: updatedEvidence as FormData
        }
      });

      return await restOperation.response
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - handleUpdateEvidence`, error);
    }
  }

  /**
   * Delete evidence from the evidence dynamo table
   * @param evidenceId id of the evidence to be deleted
   * @returns RestApi delete response
   */
  public handleDeleteEvidence = async (evidenceId: string) => {
    try {
      const restOperation = del({
        apiName: 'cwApi',
        path: `/evidence/${evidenceId}`
      });

      return await restOperation.response;
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - handleDeleteEvidence`, error);
    }
  }

  //----- Service Areas -----//
  /**
   * Get service areas by warrant id
   * @param warrantId id of the warrant
   * @returns restApi get response
   */
  public handleGetServiceAreasByWarrant = async (warrantId: string) => {
    try {
      const restOperation = get({
        apiName: 'cwApi',
        path: `/servicearea/warrant/${warrantId}`
      });

      return await restOperation.response;
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - handleGetServiceAreasByWarrant`, error);
    }
  }

  /**
   * Update service area in the service area dynamo table
   * @param updatedServiceArea Service Area to be updated
   * @returns RestApi put response
   */
  public handleUpdateServiceArea = async (updatedServiceArea: ServiceArea) => {
    try {
      if(!updatedServiceArea.id) throw new Error('Service Area is missing id!');
      const restOperation = put({
        apiName: 'cwApi',
        path: `/servicearea/${updatedServiceArea.id}`,
        options: {
          body: updatedServiceArea as FormData
        }
      });

      return await restOperation.response;
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - handleUpdateServiceArea`, error);
    }
  }

  /**
   * Delete service area from the service area dynamo table
   * @param serviceAreaId id of the service area to be deleted
   * @returns RestApi delete response
   */
  public handleDeleteServiceArea = async (serviceAreaId: string) => {
    try {
      const restOperation = del({
        apiName: 'cwApi',
        path: `/servicearea/${serviceAreaId}`
      });
      
      return await restOperation.response;
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - handleDeleteServiceArea`, error);
    }
  }

  //----- Analytics -----//
  /**
   * Create analytic in the analytic dynamo table
   * @param newAnalytic New Analytic to be created
   * @returns RestApi post response
   */
  public handleCreateAnalytic = async (newAnalytic: Analytic) => {
    try {
      if(!newAnalytic.id) throw new Error('Analytic is missing id!');
      const restResponse = post({
        apiName: 'cwApi',
        path: '/analytics/',
        options: {
          body: newAnalytic as FormData
        }
      });

      return await restResponse.response;
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - handleCreateAnalytic`, error);
    }
  }

  /**
   * Get the data from the analytics dynamo table
   * @param warrantId id of the analytic (same as the corresponding warrant id)
   * @returns RestApi get response
   */
  public handleGetAnalytic = async (analyticId: string) => {
    try {
      const restOperation = get({
        apiName: 'cwApi',
        path: `/analytics/${analyticId}`
      });
      
      return await restOperation.response;
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - handleGetAnalytic`, error);
    }
  }

  /**
   * Update analytic in the analytics dynamo table
   * @param updatedAnalytic analytic to be updated
   * @returns RestApi put response
   */
  public handleUpdateAnalytic = async (updatedAnalytic: Analytic) => {
    try {
      if(!updatedAnalytic.id) throw new Error('Analytic is missing id!');
      const restOperation = put({
        apiName: 'cwApi',
        path: `/analytics/${updatedAnalytic.id}`,
        options: {
          body: updatedAnalytic as FormData
        }
      });

      return await restOperation.response;
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - handleUpdateAnalytic`, error);
    }
  }

  /**
   * delete analytic in the warrant dynamo table
   * @param analyticId id of the analytic to be delete
   * @returns RestApi delete response
   */
  public handleDeleteAnalytic = async (analyticId: string) => {
    try {
      const restResponse = del({
        apiName: 'cwApi',
        path: `/analytics/${analyticId}`
      });

      return await restResponse.response;
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - handleDeleteAnalytic`, error);
    }
  }

  //----- Preview -----//
  /**
   * Load preview of the warrant
   * @param warrantId Id of the warrant
   * @param scope Scope of the preview
   * @returns RestApi get response
   */
  public handleLoadPreview = async (warrantId: string, scope: 'cover' | 'searches' | 'evidences' | 'grounds' | 'offenses' | 'service' | 'expedited' | 'affidavit' | 'all') => {
    try {
      const restResponse = get({
        apiName: 'cwApi',
        path: `/warrant/preview/${warrantId}/${scope}`
      });

      return await restResponse.response;
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - handleLoadPreview`, error);
    }
  }

  /**
   * Download document from the warrant
   * @param warrantId Id of the warrant
   * @param scope Scope of the document
   * @returns RestApi get response
   */
  public handleDownloadDoc = async (warrantId: string, scope: 'cover' | 'searches' | 'evidences' | 'grounds' | 'offenses' | 'service' | 'expedited' | 'affidavit' | 'all') => {
    try {
      const restResponse = get({
        apiName: 'cwApi',
        path: `/warrant/download/${warrantId}/${scope}`,
        options: {
          headers: { Accept: 'application/pdf' },
        }
      });

      return await restResponse.response;
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - handleDownloadDoc`, error);
    }
  }
}

import { Injectable } from '@angular/core';
import { UserService } from '../user-service/user.service';
import { Router } from '@angular/router';
import { DebugService } from '../debug-service/debug.service';
import { ErrorService } from '../error-service/error.service';
import { AgencyService } from '../agency-service/agency.service';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  /** Service name */
  private serviceName = 'navigation-service';

  constructor(
    private debugService: DebugService,
    private errorService: ErrorService,
    private userService: UserService,
    private agencyService: AgencyService,
    private router: Router,
  ) {
    /** Keep an eye on the user and make changes if they login/logout */
    this.userService.currentUserSubject.subscribe((user) => {
      if (!user.loggedIn) {
        this.debugService.logData(`${this.serviceName} - constructor - currentUser not signed in`, 'send user to login page' );
        // Clear other observables here
        this.router.navigate(['/login'], { replaceUrl: true });
      }
    });
  }

  /**
   * Take the user to the correct location based on the next step
   * @param nextStep 
   */
  public landingRouting = async (nextStep: string) => {
    try {
      this.debugService.logData(`${this.serviceName} - landingRouting - nextStep`, nextStep);
      switch ( nextStep ) {
        case 'DONE':
          const attributes = await this.userService.fetchUserAttributes();
          const { email_verified } = attributes;
          if (!email_verified || email_verified === 'false') {
            this.userService.sendUserAttributeVerificationCode('email');
            this.router.navigate(['/verify-email'], { replaceUrl: true });
          } else {
            // Load the user and agency
            await this.userService.loadUser();
            await this.agencyService.loadAgency();
          }
          break;
        case 'CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE':
          // Unexpected Case
          break;
        case 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED':
          this.router.navigate(['/confirm-sign-in-with-new-password'], { replaceUrl: true });
          break;
        case 'CONFIRM_SIGN_IN_WITH_SMS_CODE':
          this.router.navigate(['/mfa-confirm'], { replaceUrl: true });
          break;
        case 'CONFIRM_SIGN_IN_WITH_TOTP_CODE':
          // Unexpected Case
          break;
        case 'CONFIRM_SIGN_UP':
          // Unexpected Case
          break;
        case 'CONTINUE_SIGN_IN_WITH_MFA_SELECTION':
          // Unexpected Case
          break;
        case 'CONTINUE_SIGN_IN_WITH_TOTP_SETUP':
          // Unexpected Case
          break;
        case 'RESET_PASSWORD':
          this.router.navigate(['/reset-password'], { replaceUrl: true });
          break;
        default:
          break;
      }
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - landingRouting`, error);
    }
    
  }
}

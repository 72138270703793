import { Injectable } from '@angular/core';
import {
  signIn, type SignInInput, type SignInOutput,
  confirmSignIn, type ConfirmSignInInput, type ConfirmSignInOutput,
  resetPassword, type ResetPasswordInput, type ResetPasswordOutput,
  confirmResetPassword, type ConfirmResetPasswordInput, AuthUser,
  signOut, getCurrentUser, sendUserAttributeVerificationCode,
  VerifiableUserAttributeKey, SendUserAttributeVerificationCodeOutput,
  fetchUserAttributes, FetchUserAttributesOutput,
  confirmUserAttribute, type ConfirmUserAttributeInput,
  fetchAuthSession,
  updatePassword,
} from 'aws-amplify/auth';
import { ErrorService } from '../error-service/error.service';

@Injectable({
  providedIn: 'root'
})
export class CognitoService {

  constructor(
    private errorService: ErrorService
  ) { }

  //* ----- AWS Amplify Methods
  /**
   * Handle Sign in from Cognito
   * @param username User id
   * @param password User password
   * @returns SignInOutPut including if the user is signed in and what the next step is
   */
  public handleSignIn = async ({ username, password }: SignInInput): Promise<SignInOutput> => {
    try {
      return await signIn({ username, password });
    } catch (error) {
      // this.errorService.logError('cognito-service - handleSignIn:', error);
      throw this.errorService.passError('cognito-service - handleSignIn', error);
    }
  }

  /**
   * Handle Sign out from Cognito
   */
  public handleSignOut = async () => {
    try {
      await signOut();
    } catch (error) {
      // this.errorService.logError('cognito-service - handleSignOut:', error);
      throw this.errorService.passError('cognito-service - handleSignOut', error);
    }
  }

  /**
   * Get the current authenticated user
   * @returns Current authenticated user by idToken
   */
  public handleGetUser = async (): Promise<AuthUser> => {
    try {
      return await getCurrentUser();
    } catch (error) {
      // The reason there is no log here is because an error state
      // only implies that no user can be found (which is to be expected)
      throw this.errorService.passError('cognito-service - handleGetUser', error);
    }
  }

  /**
   * Update the user's password
   * @param oldPassword The user's current password
   * @param newPassword The user's new password
   */
  public handleUpdatePassword = async (oldPassword: string, newPassword: string) => {
    try {
      await updatePassword({ oldPassword, newPassword });
    } catch (error) {
      throw this.errorService.passError('cognito-service - handleUpdatePassword', error);
    }
  }

  /**
   * Fetch the current user's attributes
   * @returns The user's attributes
   */
  public handleFetchUserAttributes = async (): Promise<FetchUserAttributesOutput> => {
    try {
      return await fetchUserAttributes();
    } catch (error) {
      throw this.errorService.passError('cognito-service - handleFetchUserAttributes', error);
    }
  }

  /**
   * Send the user a confirmation code to verify an attribute
   * @param key The key to me verified (email/phone)
   * @returns SendUserAttributeVerificationCodeOutput
   */
  public handleSendUserAttributeVerificationCode = async ( key: VerifiableUserAttributeKey ): Promise<SendUserAttributeVerificationCodeOutput> => {
    try {
      return await sendUserAttributeVerificationCode({ userAttributeKey: key });
    } catch (error) {
      throw this.errorService.passError('cognito-service - handleSendUserAttributeVerificationCode', error);
    }
  }

  /**
   * Verify a user attribute using the code sent to them
   * @param userAttributeKey The key being verified
   * @param confirmationCode The code used the verify the attribute
   */
  public handleConfirmUserAttribute = async ({ userAttributeKey, confirmationCode }: ConfirmUserAttributeInput) => {
    try {
      await confirmUserAttribute({ userAttributeKey, confirmationCode });
    } catch (error) {
      throw this.errorService.passError('cognito-service - handleConfirmUserAttribute', error);
    }
  }

  /**
   * One time confirmation after user has signed in for the first time
   * @param challengeResponse The challenge respnse (new password)
   * @returns ConfirmSignInInput
   */
  public handleConfirmSignIn = async ({ challengeResponse }: ConfirmSignInInput): Promise<ConfirmSignInOutput> => {
    try{
      return await confirmSignIn({ challengeResponse });
    } catch (error) {
      throw this.errorService.passError('cognito-service - handleConfirmSignIn', error);
    }
  }

  /**
   * Send code to reset password for cognito user
   * @param username User id (email) of the user who wants to reset their password
   * @returns
   */
  public handleResetPassword = async ({ username }: ResetPasswordInput): Promise<ResetPasswordOutput> => {
    try {
      return await resetPassword({ username });
    } catch (error) {
      throw this.errorService.passError('cognito-service - handleResetPassword', error);
    }
  }

  /**
   * Confirm a new password for the user
   * @param username User id (email) of the user who wants to reset their password
   * @param confirmationCode Code sent to the user via email
   * @param newPassword New password
   */
  public handleConfirmResetPassword = async ({ username, confirmationCode, newPassword }: ConfirmResetPasswordInput) => {
    try {
      await confirmResetPassword({ username, confirmationCode, newPassword });
    } catch (error) {
      throw this.errorService.passError('cognito-service - handleConfirmResetPassword', error);
    }
  }

  /** Fetch the current auth session */
  public handleFetchAuthSession = async () => {
    try {
      return await fetchAuthSession();
    } catch (error) {
      throw this.errorService.passError('cognito-service - handleFetchAuthSession', error);
    }
  }
}

import { Warrant } from "../warrant/warrant";

export class ServeWarrant {
  /** AWS ID of the search warrant */
  id?: string;
  /** Status of the warrant (deptApproved is deprecated and replaced by the 'court' statuses, manualapproval is deprecated to courtManualPending) */
  status?: 'draft' | 'pending'  | 'courtManualPending' | 'courtSubmitted' | 'courtApproved' | 'courtDenied' | 'served' | 'notServed' | 'completed' | // Current statuses
  'deptapproved' | 'manualapproval'; // Deprecated statuses

  //----- Service Info -----//
  /** Specialized Served Time */
  specializedServedTime?: string;
  /** Specialized Served Date */
  specializedServedDate?: string;
  /** Specialized Served method */
  specializedServedMethod?:
  'inPerson' | 'mail' | 'fax' | 'email' | 'web';

  //-----Return Info-----//
  /** Person to whom a receipt of objects found was given */
  receiptPersons?: string[];
  /** Location at which a receipt of objects found was given */
  receiptLocations?: string[];
  /** Address to which a receipt of objects found was sent */
  receiptAddresses?: string[];
  /** If the warrant has been served */
  isServed?: boolean;
  /** Title of the affiant who marked the warrant as served */
  returnAffiantTitle?: string;
  /** First name of the affiant who marked the warrant as served */
  returnAffiantFirstName?: string;
  /** Last name of the affiant who marked the warrant as served */
  returnAffiantLastName?: string;
  /** Badge number of the affiant who marked the warrant as served */
  returnAffiantBadge?: string;
  /** Signature of the affiant who marked the warrant as served */
  returnAffiantSignature?: string;
  /** Show no return affiant signature */
  showNoReturnAffiantSig?: boolean;
  /** When the return was created */
  tsReturnCreated?: string;
  /** If the return will be done manually in front of a judge */
  returnManualReturn?: boolean;
  /** If no knock was done on the warrant */
  isNoKnockCommitted?: boolean;
  /** If warrant was completed at night */
  isNightServiceCommitted?: boolean;
  /** If an officer entered a strcutre to execute the warrant */
  isOfficerEnteredStructure?: boolean;
  /** If the warrant was obtained for a DUI */
  isDui?: boolean;

  //-----Time Stamps-----//
  /** Timestamp of when the court requested warrant to review */
  tsCourtRequested?: string;
  /** Timestamp of when the court review was complete */
  tsCourtReviewed?: string;
  /** Time the warrant was submitted to the court */
  tsSubmittedToCourt?: string;

  // Create a constuctor using a Warrant object as the input
  constructor(warrant: Warrant) {
    this.id = warrant.id;
    this.status = warrant.status;
    if(warrant.type === 'specialized') {
      this.specializedServedTime = warrant.specializedServedTime;
      this.specializedServedDate = warrant.specializedServedDate;
      this.specializedServedMethod = warrant.specializedServedMethod;
    }
    this.receiptPersons = warrant.receiptPersons;
    this.receiptLocations = warrant.receiptLocations;
    this.receiptAddresses = warrant.receiptAddresses;
    this.isServed = warrant.isServed;
    this.returnAffiantTitle = warrant.returnAffiantTitle;
    this.returnAffiantFirstName = warrant.returnAffiantFirstName;
    this.returnAffiantLastName = warrant.returnAffiantLastName;
    this.returnAffiantBadge = warrant.returnAffiantBadge;
    this.returnAffiantSignature = warrant.returnAffiantSignature;
    this.showNoReturnAffiantSig = warrant.showNoReturnAffiantSig;
    this.tsReturnCreated = warrant.tsReturnCreated;
    this.returnManualReturn = warrant.returnManualReturn;
    this.isNoKnockCommitted = warrant.isNoKnockCommitted;
    this.isNightServiceCommitted = warrant.isNightServiceCommitted;
    this.isOfficerEnteredStructure = warrant.isOfficerEnteredStructure;
    this.isDui = warrant.isDui;
    this.tsCourtRequested = warrant.tsCourtRequested;
    this.tsCourtReviewed = warrant.tsCourtReviewed;
    this.tsSubmittedToCourt = warrant.tsSubmittedToCourt;
  }
}

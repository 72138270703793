import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Agency } from 'src/app/classes/agency/agency/agency';
import { DebugService } from '../debug-service/debug.service';
import { ErrorService } from '../error-service/error.service';
import { UtiltyService } from '../utility-service/utilty.service';
import { DynamoService } from '../dynamo-service/dynamo.service';
import { IntercessorService } from '../intercessor-service/intercessor.service';
import { CourtConnection } from 'src/app/classes/utility/selection/court-connection/court-connection';

@Injectable({
  providedIn: 'root'
})
export class AgencyService {
  /** Name of the service */
  private serviceName = 'agency-service';
  /** Blank agency object */
  private blankAgency: Agency = {};

  /** The current agency */
  public currentAgencySubject = new BehaviorSubject<Agency>(this.blankAgency);

  constructor(
    private debugService: DebugService,
    private errorService: ErrorService,
    private dynamoService: DynamoService,
    private intercessorService: IntercessorService,
  ) {
    // This really shouldn't happen as we dont expect the user to be changing the agency
    // This is only used to visualize when the agency is loaded
    this.currentAgencySubject.subscribe((agency) => {
      this.debugService.logData(`${this.serviceName} - currentAgencySubject - New Value:`, agency);
    });
  }

  //* ----- Dynamo
  /**
   * Get the agency from the agency table
   * @returns The agency
   */
  public getAgency = async () => {
    try {
      const agencyApiResponse = await this.dynamoService.handleGetAgency();
      this.debugService.logData(`${this.serviceName} - getAgency - agencyApiResponse:`, agencyApiResponse);
      if((agencyApiResponse).statusCode === 200) {
        // This api will return a list of all agencies in the agency table
        const newAgency = await agencyApiResponse.body.json() as Agency[];
        this.debugService.logData(`${this.serviceName} - getAgency - agency body json:`, newAgency);
        // We want the only agency in the table (first element)
        return newAgency[0] as Agency;
      } else {
        throw new Error('Invalid get agency response');
      }
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - getAgency`, error);
    }
  }

  //* ----- Intercessor
  /**
   * Get the court connections for the agency
   * @param agencyId The agency ID
   * @returns RestApi post response
   */
  public getAgencyCourtConnections = async (agencyId: string) => {
    try {
      const agencyApiResponse = await this.intercessorService.handleGetAgencyCourtConnections(agencyId);
      this.debugService.logData(`${this.serviceName} - getAgencyCourtConnections - agencyApiResponse:`, agencyApiResponse);
      if (agencyApiResponse.statusCode === 200) {
        const innerApiResponse = await agencyApiResponse.body.json() as { body: string, headers: { [key: string]: string }, statusCode: number };
        this.debugService.logData(`${this.serviceName} - getAgencyCourtConnections - innerApiResponse:`, innerApiResponse);
        const courtConnectionJson = JSON.parse(innerApiResponse.body) as { courtName: string, agencyId: string, courtId: string }[];
        const courtConnections = courtConnectionJson.map((courtConnection) => new CourtConnection(courtConnection));
        this.debugService.logData(`${this.serviceName} - getAgencyCourtConnections - courtConnections:`, courtConnections);
        return courtConnections;
      } else {
        throw new Error('Invalid get agency court connections response');
      }
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - getAgencyCourtConnections`, error);
    }
  }

  //* ----- Local
  /**
   * Resets the current agency
   */
  public resetCurrentAgency = () => {
    this.currentAgencySubject.next(this.blankAgency);
  }

  /**
   * Sets the current agency
   * @param agency Agency to become the current warrant
   */
  public setCurrentAgency = (agency: Agency) => {
    this.currentAgencySubject.next(agency);
  }

  /**
   * Returns the current agency
   * @returns The current agency
   */
  public getCurrentAgency = () : Agency => {
    return this.currentAgencySubject.getValue();
  }

  /**
   * Get the agency from dynamo and set the current agency to current agency subject 
   */
  public loadAgency = async () => {
    try {
      const dynamoAgency = await this.getAgency();
      this.setCurrentAgency({
        ...dynamoAgency
      });
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - loadAgency`, error);
    }
  }
}

import { Component } from '@angular/core';
import { IonApp, IonRouterOutlet } from '@ionic/angular/standalone';
import { NavigationService } from './services/navigation-service/navigation.service';
import { Amplify } from 'aws-amplify';
import { environment } from '../environments/environment';
import { ActivityService } from './services/activity-service/activity.service';

//***** Initialize Amplify *****//
// Amplify.configure(environment.awsAmplifyConfig);
Amplify.configure(environment.awsAmplifyConfig);

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  standalone: true,
  imports: [IonApp, IonRouterOutlet],
})
export class AppComponent {
  constructor(
    private navigationService: NavigationService, // Initialize so user observable is visable at all times
    private activityService: ActivityService // Initialize so activity tracker is available at all times
  ) { }
}

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DebugService {

  constructor() { }

  /**
   * Log data
   * Will only log if the environment is not production
   * @param processor The serive/page/component processing the code
   * @param data The data to be logged
   */
  public logData = (processor: string, data: any) => {
    if(!environment.production) {
      console.log(processor, data);
    }
  }
}

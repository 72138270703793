import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CognitoService } from '../cognito-service/cognito.service';
import { ErrorService } from '../error-service/error.service';
import { Router } from '@angular/router';
import { DebugService } from '../debug-service/debug.service';
import { User } from 'src/app/classes/users/user/user';
import { DynamoService } from '../dynamo-service/dynamo.service';
import { AlertController } from '@ionic/angular';
import { UiService } from '../ui-service/ui.service';
import { CenturionService } from '../centurion-service/centurion.service';
import { S3Service } from '../s3-service/s3.service';
import { WarrantService } from '../warrant-service/warrant.service';
import { UtiltyService } from '../utility-service/utilty.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  /** Name of the service */
  private serviceName = 'user-service';
  /** Blank user object */
  private blankUser: User = { id: '', loggedIn: false };

  /** The current user */
  public currentUserSubject = new BehaviorSubject<User>(this.blankUser);

  constructor(
    private cognitoService: CognitoService,
    private dynamoService: DynamoService,
    private errorService: ErrorService,
    private debugService: DebugService,
    private router: Router,
    private alertController: AlertController,
    private uiService: UiService,
    private centurionService: CenturionService,
    private s3Service: S3Service,
    private warrantService: WarrantService,
    private utilitySetvice: UtiltyService,
  ) {
    this.currentUserSubject.subscribe((user) => {
      this.debugService.logData(`${this.serviceName} - currentUserSubject - New Value:`, user);
    });
  }

  //* ----- COGNITO
  /** Sign the user in
   * @param username Username entered by the user
   * @param password Password entered by the user
   * @returns Next step to be taken
   */
  public signIn = async (username: string, password: string) => {
    try {
      const signInResponse = await this.cognitoService.handleSignIn({ username, password });
      const { isSignedIn, nextStep } = signInResponse;
      this.debugService.logData(`${this.serviceName} - signIn response:`, signInResponse);
      return nextStep;
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - signIn`, error);
    }
  }

  /** Sign the user out */
  public signOut = async () => {
    try {
      await this.cognitoService.handleSignOut();
      this.resetCurrentUser();
      this.warrantService.resetCurrentWarrants();
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - signOut`, error);
    }
  }

  /**
   * Confirm user sign-in (one time password change)
   * @param challengeResponse
   * @returns Next step to be taken
   */
  public confirmSignIn = async (challengeResponse: string) => {
    try {
      const { isSignedIn, nextStep } = await this.cognitoService.handleConfirmSignIn({ challengeResponse });
      this.debugService.logData(`${this.serviceName} - confirmSignIn response:`, {isSignedIn: isSignedIn, nextStep: nextStep});
      return nextStep;
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - confirmSignIn`, error);
    }
  }

  /**
   * Reset the password of a user
   * @param username User resetting their password
   * @returns Next step to be taken
   */
  public resetPassword = async (username: string) => {
    try {
      const { isPasswordReset, nextStep } = await this.cognitoService.handleResetPassword({ username });
      this.debugService.logData(`${this.serviceName} - resetPassword response:`, { isPasswordReset: isPasswordReset, nextStep: nextStep });
      return nextStep;
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - resetPassword`, error);
    }
  }

  /**
   * Confirm reset of the password of the user
   * @param username User resetting their password
   * @param confirmationCode Code sent to the user
   * @param newPassword New password
   */
  public confirmResetPassword = async (username: string, confirmationCode: string, newPassword: string) => {
    try {
      await this.cognitoService.handleConfirmResetPassword({ username, confirmationCode, newPassword });
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - confirmPasswordReset`, error);
    }
  }

  /**
   * Update the password of the user
   * @param oldPassword User's old password
   * @param newPassword User's new password
   */
  public updatePassword = async (oldPassword: string, newPassword: string) => {
    try {
      await this.cognitoService.handleUpdatePassword(oldPassword, newPassword);
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - updatePassword`, error);
    }
  }

  /**
   * Allow the user in if they already have a token
   * @returns If there is an existing user or not
   */
  public verifyToken = async () => {
    try {
      const { username: username, userId: userId, signInDetails: signInDetails } = await this.cognitoService.handleGetUser();
      this.debugService.logData(`${this.serviceName} - verifyToken handleGetUser response:`, { username: username, userId: userId, signInDetails: signInDetails });
      if(username && userId && signInDetails) {
        this.debugService.logData(`${this.serviceName} - verifyToken:`, 'Token not expired');
        return true;
      } else {
        this.debugService.logData(`${this.serviceName} - refreshToken:`, 'Token expired, clearing token');
        // When there is a refresh token, but it is not valid
        this.resetCurrentUser();
        this.warrantService.resetCurrentWarrants();
        this.cognitoService.handleSignOut();
        return false;
      }
    } catch (error) {
      // There is no user
      return false;
    }
  }

  /**
   * Fetch the user attributes from cognito
   * @returns user attributes
   */
  public fetchUserAttributes = async () => {
    try {
      const attributes = await this.cognitoService.handleFetchUserAttributes();
      this.debugService.logData(`${this.serviceName} - fetchUserAttributes response:`, attributes);
      return attributes;
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - fetchUserAttributes`, error);
    }
  }

  /**
   * Verify a user attribute (email or phone number) by sending a code to the desired attribure
   * @param key Key to be verified
   */
  public sendUserAttributeVerificationCode = async (key: 'email' | 'phone_number') => {
    try {
      const sendUserAttributeVerificationCodeResponse = this.cognitoService.handleSendUserAttributeVerificationCode(key);
      this.debugService.logData(`${this.serviceName} - sendUserAttributeVerificationCode response`, sendUserAttributeVerificationCodeResponse);
      return sendUserAttributeVerificationCodeResponse;
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - sendUserAttributeVerificationCode`, error);
    }
  }

  /**
   * Verify a user attribute (email or phone number) using a confirmation code sent to the user
   * @param userAttributeKey Key being verified
   * @param confirmationCode Code sent to the user
   */
  public confirmUserAttribute = async (userAttributeKey: 'email' | 'phone_number', confirmationCode: string) => {
    try {
      await this.cognitoService.handleConfirmUserAttribute({userAttributeKey, confirmationCode});
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - confirmUserAttribute`, error);
    }
  }

  /** Fetch the user's auth session */
  public fetchAuthSession = async () => {
    try {
      const authSession = await this.cognitoService.handleFetchAuthSession();
      this.debugService.logData(`${this.serviceName} - fetchAuthSession response:`, authSession);
      this.debugService.logData(`${this.serviceName} - fetchAuthSession tokens.accessToken.payload:`, authSession.tokens?.accessToken.payload);
      return authSession;
    } catch (error) {
      throw this.errorService.passError(`${this.errorService} - fetchAuthSession`, error);
    }
  }

  //* ----- DYNAMO
  /**
   * Get user data from dynamo
   * @param subId The cognito subid of the user
   * @returns User object from dynamo
   */
  public getUser = async (subId: string) => {
    try {
      const userApiResponse = await this.dynamoService.handleGetUser(subId);
      this.debugService.logData(`${this.serviceName} - getUser - userApiResponse:`, userApiResponse);
      if(userApiResponse.statusCode === 200) {
        const newUser = await userApiResponse.body.json();
        this.debugService.logData(`${this.serviceName} - getUser - user body json:`, newUser);
        return newUser as User;
      } else {
        throw new Error('Invalid get user response');
      }
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - getUser`, error);
    }
  }

  /**
   * Update user data from dynamo
   * @param user The dynamo user to be updated
   */
  public updateUser = async (user: User) => {
    try {
      const userApiResponse = await this.dynamoService.handleUpdateUser(user);
      this.debugService.logData(`${this.serviceName} - updateUser - userApiResponse:`, userApiResponse);
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - updateUser`, error);
    }
  }

  //* ----- S3
  /**
   * Get the signature url of the current user
   * @param path signature path
   * @returns Signature url
   */
  public getMySignatureUrl = async () => {
    try {
      const currentUserSignatureKey = this.getCurrentUser().signatureKey;
      return (await this.s3Service.handleGetUrl('private', currentUserSignatureKey!))!.url.toString();
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - getMySignatureUrl`, error);
    }
  }

  /**
   * Get the signature of the current user as a base64 string
   * @returns Signature as base64
   */
  public getMySignatureAsBase64 = async () => {
    try {
      const currentUserSignatureKey = this.getCurrentUser().signatureKey;
      // Get the user signature
      const signatureFile = await (await this.s3Service.handleGetFile('private', currentUserSignatureKey!))?.body.blob();
      if(!signatureFile) throw new Error('No signature file found');
      return await this.utilitySetvice.blobToBase64(signatureFile);
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - getMySignatureAsBase64`, error);
    }
  }

  /**
   * Save the user signature to the warrant (as affiant)
   * @param path The path to the signature
   * @param warrantId Warrant id
   */
  public saveMySignatureToWarrant = async (warrantId: string) => {
    try {
      const currentUserSignatureKey = this.getCurrentUser().signatureKey;
      // Get the user signature
      const signatureFile = await (await this.s3Service.handleGetFile('private', currentUserSignatureKey!))?.body.blob();
      if(!signatureFile) throw new Error('No signature file found');
      // Save the user signature to the warrant s3
      await this.s3Service.handleSaveFile('public', `warrant/${warrantId}/${currentUserSignatureKey}`, new File([signatureFile], currentUserSignatureKey!));
      return currentUserSignatureKey;
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - saveMySignatureToWarrant`, error);
    }
  }

  /**
   * Upload signature
   * @param path Path to the file
   * @param file
   * @returns
   */
  public uploadMySignature = async (path: string, file: File) => {
    try {
      return await this.s3Service.handleSaveFile('private', path, file);
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - uploadMySignature`, error);
    }
  }

  //* ----- LOCAL
  /**
   * Resets the current user
   */
  public resetCurrentUser = () => {
    this.currentUserSubject.next(this.blankUser);
  }

  /**
   * Sets the current user
   * @param warrant User to become the current user
   */
  public setCurrentUser = (user: User) => {
    this.currentUserSubject.next(user);
  }

  /**
   * Returns the current user stored locally
   * @returns The current user
   */
  public getCurrentUser = () : User => {
    return this.currentUserSubject.getValue();
  }

  /**
   * Determine if the user has all the required atributes
   * @returns User is complete
   */
  public userIsComplete = () : boolean => {
    try {
      const isComplete = false;
      const currentUser = this.getCurrentUser();
      if(
        (currentUser.firstName && currentUser.firstName != '') &&
        (currentUser.lastName && currentUser.lastName != '') &&
        (currentUser.title && currentUser.title != '') &&
        (currentUser.badge && currentUser.badge != '') &&
        (currentUser.email && currentUser.email != '') &&
        (currentUser.signatureKey && currentUser.signatureKey != '')
      ) return true;
      else return false;
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - userIsComplete`, error);
    }
  }

  /**
   * Determine if the user has agreed to the user agreement
   * @returns User agreed to the agreement
   */
  public userHasAgreedToUserAgreement = () : boolean => {
    try {
      const currentUser = this.getCurrentUser();
      if(!currentUser.agreementVersion || currentUser.agreementVersion !== this.centurionService.getUserAgreementLatestVersion()) {
        return false;
      } else {
        return true;
      }
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - userHasAgreed`, error);
    }
  }


  /**
   * Load the dynamo user into the current user observable if it does not already exist
   * @param sub The sub id of the user
   */
  public loadUser = async () => {
    try {
      const currentUserSubject = this.getCurrentUser();
      this.debugService.logData(`${this.serviceName} - loadUser - current user:`, currentUserSubject);
      // If there is no user object
      if(currentUserSubject.id === "") {
        this.debugService.logData(`${this.serviceName} - loadUser:`, 'NO USER INFO');
        // Get Sub Id
        const authSession = await this.fetchAuthSession();
        if(!authSession.userSub) throw new Error('User has no sub id');
        const dynamoUser = await this.getUser(authSession.userSub);
        // Add the user to patrol if they have no other units (default case)
        if(!dynamoUser.units) dynamoUser.units = ['Patrol'];
        // Set the current user object
        this.setCurrentUser({
          ...dynamoUser,
          loggedIn: true,
          groups: authSession.tokens?.accessToken.payload['cognito:groups'] as string[] || []
        });
        // If the user has not agreed to the user agreement
        if(!this.userHasAgreedToUserAgreement()) {
          this.presentUserAgreement();
        }
        // Take them to the account page if they have no user info
        else if(!this.userIsComplete()) {
          this.router.navigate(['/account'], { replaceUrl: true });
          this.uiService.presentWelcomeAlert();
        }
        // Allow them to enter if they already have user info
        else {
          this.router.navigate(['/dashboard'], { replaceUrl: true });
        }
      }
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - loadUser`, error);
    }
  }

  //* ----- UI
  private presentUserAgreement = async () => {
    try {
      const versionNumber = this.centurionService.getUserAgreementLatestVersion();
      const alertMessage = `Centurion's End User License Agreement (v${versionNumber})`;
      const currentUser = this.getCurrentUser();

      const agreementAlert = await this.alertController.create({
        header: 'End User Agreement',
        subHeader: 'Please review the following agreement, it is necessary to agree before continuing',
        message: alertMessage,
        buttons: [
          {
            text: 'View Agreement',
            handler: () => {window.open('assets/userAgreement/07.02.22.pdf'); return false; },
          },
          {
            text: 'Accept',
            role: 'confirm',
            handler: () => {
              // Update Local user
              this.setCurrentUser({
                ...currentUser,
                agreementVersion: versionNumber,
                agreementDate: new Date().toISOString()
              });
              // Update Dynamo user
              this.updateUser({
                id: currentUser.id,
                agreementVersion: versionNumber,
                agreementDate: new Date().toISOString()
              });
            }
          },
          {
            text: 'Decline',
            role: 'cancel',
            handler: () => {
              this.signOut();
            }
          },
        ]
      });

      await agreementAlert.present();
      agreementAlert.onDidDismiss().then((detail) => {
        // Only activate code if the user agreed
        if(detail.role === 'confirm'){
          // Take them to the account page if they have no user info
          if(!this.userIsComplete()) {
            this.router.navigate(['/account'], { replaceUrl: true });
            this.uiService.presentWelcomeAlert();
          }
          // Allow them to enter if they already have user info
          else {
            this.router.navigate(['/dashboard'], { replaceUrl: true });
          }
        }
      });
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - presentUserAgreement`, error);
    }
  }
}

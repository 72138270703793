import { Injectable } from '@angular/core';
import { LoadingController, ToastController, AlertController } from '@ionic/angular';
import { IonInput } from '@ionic/angular';
import { IonInput as StandAloneIonInput } from '@ionic/angular/standalone';
import { Evidence } from 'src/app/classes/warrants/evidence/evidence';
import { ErrorService } from '../error-service/error.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UiService {
  /** Name of the service */
  private serviceName = 'uiService';
  /** Ion Loading Element */
  private loader: HTMLIonLoadingElement | undefined;

  /** If the top level loader is visible */
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();

  /** Message shown on the top level loader */
  private messageSubject = new BehaviorSubject<string>('');
  public message$ = this.messageSubject.asObservable();

  constructor(
    private loadingController: LoadingController,
    private toastController: ToastController,
    private errorService: ErrorService,
    private alertController: AlertController,
  ) { }

  /**
   * Begin a loading animation that prevents the user from interacting with the page
   * @param message Message to show the user
   */
  public startBlockingLoading = async (message: string) => {
    try {
      this.loader = await this.loadingController.create({
        message: message,
        spinner: 'dots',
        backdropDismiss: false,
      });

      this.loader.present();
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - startBlockingLoading`, error);
    }
  }

  /**
   * End the loading animation
   */
  public endBlockingLoading = async () => {
    try {
      this.loader?.dismiss();
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - endBlockingLoading`, error);
    }
  }

  /**
   * Show Success via toast
   * @param message Description of the success
   */
  public showSuccessToast =  async (message: string) => {
    try {
      const errorToast = this.toastController.create({
        message: message,
        duration: 2000,
        color: 'success',
        position: 'top'
      });
      (await errorToast).present();
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - showSuccessToast`, error);
    }
  }

  /**
   * Show Error via toast
   * @param message Description of the failure
   */
  public showErrorToast =  async (message: string) => {
    try {
      const errorToast = this.toastController.create({
        message: 'An error occured: ' + message,
        duration: 3500,
        color: 'danger',
        position: 'top'
      });
      (await errorToast).present();
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - showErrorToast`, error);
    }
  }

  /**
   * Return a string describing the warrant type
   * @param warrantType Type of the warrant
   * @returns String describing the warrant type
   */
  public getWarrantTypeString = (warrantType: 'warrant' | 'gps' | 'order' | 'specialized') => {
    try {
      switch (warrantType){
        case 'warrant':
          return 'Search Warrant';
        case 'gps':
          return 'GPS Warrant';
        case 'order':
          return 'Court Order';
        case 'specialized':
          return 'Records Warrant';
      }
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - getWarrantTypeString`, error);
    }
  }

  /**
   * Return a string describing the warrant status
   * @param warrantStatus Status of the warrant
   * @returns String describing the warrant status
   */
  public getWarrantStatusString = (warrantStatus: 'draft' | 'pending' | 'courtManualPending' | 'courtSubmitted' | 'courtApproved' | 'courtDenied' | 'served' | 'notServed' | 'completed' | 'deptapproved' | 'manualapproval') => {
    try {
      switch(warrantStatus) {
        case 'draft':
          return 'Draft'
        case 'pending':
          return 'Pending Review'
        case 'courtManualPending':
          return 'Awaiting Manual Court Review'
        case 'courtSubmitted':
          return 'Awaiting Court Review'
        case 'courtApproved':
          return 'Approved by Court'
        case 'courtDenied':
          return 'Denied by Court'
        case 'served':
          return 'Served'
        case 'notServed':
          return 'Not Served';
        case 'completed':
          return 'Completed';
        // These cases should never be seen by the user as they are depricated
        case 'deptapproved':
          return 'Department Approved'
        case 'manualapproval':
          return 'Manual Approval'
      }
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - getWarrantStatusString`, error);
    }
  }

  /**
   * Get the date of the date as a string
   * @param dateIsoString ISO string of the date
   * @returns Date as a string
   */
  public getDateString = (dateIsoString: string) => {
    try {
      const date = new Date(dateIsoString);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
      const year = date.getFullYear();

      return `${month}/${day}/${year}`;
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - getDateString`, error);
    }
  }

  /**
   * Get the time of the date as a string
   * @param dateIsoString ISO string of the date
   * @returns Time as a string
   */
  public getTimeString = (dateIsoString: string) => {
    try {
      const date = new Date(dateIsoString);
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');

      return `${hours}:${minutes}`;
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - getDateString`, error);
    }
  }

  /** Iterate the focus onto the next field when input has reached the max amount of characters */
  public gotoNextField(nextElement: IonInput | StandAloneIonInput) {
    try {
      nextElement.setFocus();
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - gotoNextField`, error);
    }
  }

  /**
   * Get the title for evidences to preview on evidence items
   * @param evidence The evidence
   * @param canEdit If the evidence may be edited
   * @returns The string to preview the evidence
   */
  public getEvidenceTitleString = (evidence: Evidence, canEdit: boolean) => {
    try {
      let titleString = evidence.template ? `(${evidence.template}) ` : '';
      if(canEdit) {
        if(evidence.description!.length > 200) titleString += evidence.description!.substring(0,200) + '...';
        else titleString += evidence.description;
      } else {
        titleString += evidence.description;
      }
      return titleString
    } catch (error) {
      throw this.errorService.passError('ui-service - getEvidenceTitleString', error);
    }
  }

  /**
   * Present alert to the user for their first login
   */
  public presentWelcomeAlert = async () => {
    try {
      const welcomeAlert = await this.alertController.create({
        header: 'Welcome to Centurion Warrants',
        message: 'Before you may continue, you are required to finish setting up your profile',
        buttons: [
          {
            text: 'OK',
            role: 'confirm',
            handler: () => {

            },
          },
        ],
      });

      welcomeAlert.present();
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - presentWelcomeAlert`, error);
    }
  }

  /**
   * Present alert to the user for their first login
   * @param seconds Seconds to convert to minutes
   * @returns String of the seconds converted to minutes
   */
  public secondsToMinutesString = (seconds: number) => {
    try {
      const minutes = Math.floor(seconds / 60);
      // Get the remaining seconds to the nearest second
      const remainingSeconds = Math.round(seconds % 60);
      return `${minutes}m ${remainingSeconds}s`;
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - secondsToMinutesString`, error);
    }
  }
}
